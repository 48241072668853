/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import Loading from "../Loading";
import VesterrFillInImgBlack from "../../global/img/VesterrFillInImgBlack.png";
// import { nFormatter, nTextFormatter } from "../utils";
import "./style.css";

function DealCards(props) {
  const { userActions, deal, isHome } = props;

  const navigate = useNavigate();

  if (userActions?.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Col lg={isHome ? 4 : 4}>
        <div
          className={`${isHome ? "subDealCol" : "offeringsCard"}`}
          onClick={() =>
            !deal?.closed &&
            !deal?.complete &&
            navigate(`/funderr/deal/${deal?.slug}`)
          }
          style={{
            cursor: deal?.closed || deal?.complete ? "not-allowed" : "pointer",
          }}
        >
          <div
            className="subDealImgDiv"
            style={{
              background: `url(${
                (deal?.images?.length && deal?.images[0]) ||
                VesterrFillInImgBlack
              })`,
            }}
          >
            <p className="dealRegType sourceBold">{deal?.fund_type}</p>
            {deal?.closes_soon || deal?.closed || deal?.complete ? (
              <p
                className={`${
                  deal.complete
                    ? "dealCardStatus dealCardStatusFunded"
                    : "dealCardStatus"
                } sourceBold`}
              >
                {deal?.closed
                  ? deal?.complete
                    ? "FUNDED"
                    : "CLOSED"
                  : "CLOSING SOON"}
              </p>
            ) : null}
            {/* {!isHome ? (
              <div className="dealCardProgressBarDiv">
                <p className="dealCardProgressBarLabel dealCardProgressBarLabelLeft">
                  ${nFormatter(deal?.funds_invested, 1, true)}
                  <br />
                  <span className="dealCardProgressBarSubLabel">
                    {nTextFormatter(deal?.funds_invested)} Raised
                  </span>
                </p>
                <div className="dealCardProgressBarBorder">
                  <div
                    className="dealCardProgressBar"
                    style={deal?.progress_bar}
                  />
                </div>
                <p className="dealCardProgressBarLabel dealCardProgressBarLabelRight">
                  ${nFormatter(deal?.targetAmount, 1, true)}
                  <br />
                  <span className="dealCardProgressBarSubLabel">
                    {nTextFormatter(deal?.targetAmount)} Raising
                  </span>
                </p>
              </div>
            ) : null} */}
            <div className="offeringsCardImgOverlay" />
          </div>
          <h3 className="offeringsCardTitle sourceBold">{deal?.name}</h3>
          <p className="offeringsCardLocation sourceBold">{deal?.location}</p>
          {deal?.demo_deal ? <p>Testing the Waters Phase</p> : null}
          {/* <p
            className={`${
              isHome ? "subDealDesc" : "offeringsCardDesc"
            } sourceRegular`}
          >
            {deal?.description}
          </p> */}
          {/* {isHome ? (
            <button
              onClick={() => navigate(`/funderr/deal/${deal?.deal_id}`)}
              className="subDealLink sourceRegular hoverDelay"
              title="Link"
            >
              Learn More
            </button>
          ) : ( */}
          <button
            className="offeringsCardLearnMore hoverDelay sourceBold"
            title="Learn More"
            // onClick={() => navigate(`/funderr/deal/${deal?.deal_id}`)}
          >
            Learn More
          </button>
          {/* )} */}
        </div>
      </Col>
    </>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
});

export default connect(mapStateToProps)(DealCards);
